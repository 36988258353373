import dayjs from "dayjs";
import * as React from "react";
import getParsedJwt from "./../util/parseToken";
import client from "./../util/api-client";

import { setLocalStorage, getLocalStorage } from "./../util/localStorage";

type LoginCheck = {
  username: string;
  token: string;
  refreshToken: string;
};

const _login = async (
  username: string,
  password: string
): Promise<LoginCheck | void> => {
  const formData = new FormData();
  formData.append("_username", username);
  formData.append("_password", password);
  try {
    const result = await fetch(
      "https://mathilda.swissconnect.ch/api/v2/login_check",
      { method: "POST", body: formData }
    );
    const json = await result.json();
    const data: { token: string; refreshToken: string } = json;
    return { username, ...data };
  } catch (error) {
    throw new Error("Login not successful");
  }
};

type Values = {
  token: string;
  isExpired: boolean;
  login: (username: string, password: string) => Promise<string>;
  username: string;
  logout: () => void;
};

const defaultValues: Values = {
  token: "",
  username: "",
  isExpired: true,
  login: async (username, password) => await new Promise(() => ""),
  logout: () => null,
};

type Token = {
  username: string;
  exp: number;
  iat: number;
};

const AuthContext = React.createContext(defaultValues);

function AuthProvider(props: any) {
  const [token, setToken] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [refreshToken, setRefreshToken] = React.useState("");
  const isExpired: boolean = React.useMemo(() => {
    if (!token) return true;
    const parsedToken = getParsedJwt<Token>(token);
    if (!parsedToken) return true;
    const { exp } = parsedToken;
    return dayjs(exp).isAfter(dayjs());
  }, [token]);

  const login = async (username: string, password: string): Promise<string> => {
    try {
      const tokenResponse = await _login(username, password);
      if (tokenResponse) {
        setUsername(tokenResponse.username);
        setToken(tokenResponse.token);
        setRefreshToken(tokenResponse.refreshToken);
        return tokenResponse.token;
      }
    } catch (e: any) {}
    setToken("");
    setRefreshToken("");
    return "";
  };

  const logout = () => {
    setToken("");
    setRefreshToken("");
  };
  React.useEffect(() => {
    setToken(getLocalStorage("token"));
    setUsername(getLocalStorage("username"));
    setRefreshToken(getLocalStorage("refreshToken"));
  }, []);

  React.useEffect(() => {
    const lsToken = getLocalStorage("token");
    if (lsToken !== token || !token) {
      setLocalStorage("token", token);
    }
    const lsUsername = getLocalStorage("username");
    if (lsUsername !== username || !username) {
      setLocalStorage("username", username);
    }
    const lsRefreshToken = getLocalStorage("refreshToken");
    if (lsRefreshToken !== refreshToken || !refreshToken) {
      setLocalStorage("refreshToken", refreshToken);
    }
  }, [token, refreshToken, username]);

  return (
    <AuthContext.Provider
      value={{ token, login, logout, isExpired, username }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("Must be used within AuthProvider");
  }
  return context;
}

function useIsZH() {
  const { username } = useAuth();
  return username.includes("800");
}

function useSCClient() {
  const { token } = useAuth();
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

export { useAuth, AuthProvider, AuthContext, useSCClient, useIsZH };
